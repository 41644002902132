/**
 * Scripts
 */

import bodyClass from '../scripts/_bodyclass'
// import gridOverlay from '../../../templates/partials/gridOverlay/_script'
// import barba from '../scripts/barba';
import disableHover from '../scripts/_disableHover'
import resizeClass from '../scripts/_resizeClass'
import '../scripts/_lazyloading'
import '../scripts/_fontfaceobserver'
// import privacyIframe from '../scripts/_privacyIframe'
// import detectSectionBg from '../scripts/_detectSectionBg'
import microModal from '../scripts/_microModal'
import '../scripts/_pagetransition'
import '../scripts/_smoothScroll'
import isotope from '../scripts/_isotope'
import rellax from '../scripts/_rellax'
import scrollReveal from '../scripts/_scrollReveal'

// Modules Array
const scriptsArray = [
  bodyClass,
  // barba,
  // privacyIframe,
  disableHover,
  resizeClass,
  isotope,
  // detectSectionBg,
  microModal,
  rellax,
  scrollReveal

  // gridOverlay
]

const scripts = {
  // Scripts
  Scripts() {
    if (scriptsArray.length > 0 && scriptsArray !== undefined) {
      scriptsArray.forEach((script) => {
        script.init()
      })
    }
  },

  init() {
    this.Scripts()
  }
}

export default scripts
